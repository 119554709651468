<template>
	<div class="page">
		<div class="titleBox">
			<div class="title">{{lang==6?'搜索结果':'Search Results'}}</div>
			<div class="inputBox">
				<!-- <input v-model="keyword" :placeholder="lang==6?'输入关键词':'Enter the search term'" /> -->
				<el-select v-model="keyword" filterable :placeholder="lang==6?'输入关键词':'Enter the search term'"
					:filter-method="filterMethod">
					<el-option v-for="(item,index) in keyList" :key="index" :label="lang==6?item.cn:item.en"
						:value="lang==6?item.cn:item.en">
					</el-option>
				</el-select>
				<div class="btn" @click="getInfo">{{lang==6?'搜索':'Search'}}</div>
			</div>
		</div>
		<div class="content">
			<div class="list">
				<div v-if="list.length>0">
					<div class="item" v-for="(item,index) in list" :key="index" @click="$router.push(item.url)">
						<p>{{item.title}}</p>
						<div v-html="item.describe"></div>
					</div>
				</div>
				<div v-else style="text-align: center;">未找到结果</div>
			</div>
		</div>
	</div>
</template>

<script>
	import mixinLang from '../components/mixin.js'
	export default {
		mixins: [mixinLang],
		data() {
			return {
				keyword: '',
				list: [],
				keyList: [{
						cn: '奥迪',
						en: 'Audi',
					},
					{
						cn: '宾利',
						en: 'Bentley'
					},
					{
						cn: '兰博基尼',
						en: 'Lamborghini'
					},
					{
						cn: '保时捷',
						en: 'Porsche'
					},
					{
						cn: '宝马',
						en: 'BMW'
					},
					{
						cn: 'MINI',
						en: 'MINI'
					},
					{
						cn: '集团介绍',
						en: 'Group Intro',
						url: '/brandStory/introduce'
					},
					{
						cn: '集团新闻',
						en: 'Group News',
						url: '/news'
					},
					{
						cn: '集团历程',
						en: 'Group Milestone',
						url: '/history'
					},
					{
						cn: 'Women@PIAG',
						en: 'Women@PIAG',
						url: '/paigWomen'
					},
					{
						cn: '合规与廉正',
						en: 'Compliance & Integrity',
						url: '/compliance'
					},
					{
						cn: '培训学院',
						en: 'Training Academy',
						url: '/aboutUs/AFCC'
					}
				],
			}
		},
		watch: {
			$route(newRoute) {
				this.keyword = newRoute.query.keyword // 这里就是新的query
				this.getInfo()
			}
		},
		methods: {
			filterMethod(e) {
				this.keyword = e
			},
			getInfo() {
				var that = this
				if (this.$route.query.keyword && !this.keyword) {
					this.keyword = this.$route.query.keyword
					if (new Blob([this.keyword]).size < 3) {
						return this.$message({
							message: '请输入中文至少一位，英文至少三位进行搜索',
							type: 'warning',
						});
					}
				}

				this.$api.getInformationList({
					word: this.keyword
				}).then(res => {
					res.list.forEach(item => {
						var i = item.describe.indexOf(that.keyword)
						// 关键字出现在前二十个字符中
						if (i > 20 && item.describe.length < 300) {
							item.describe = item.describe.replace(new RegExp(that.keyword, "gm"),
								`<span style="background-color: #B5A191;">${that.keyword}</span>`
							)
						} else {
							// 关键字出现在二十个字符之后
							var str = '...'
							str += item.describe.slice(i)
							item.describe = str.replace(new RegExp(that.keyword, "gm"),
								`<span style="background-color: #B5A191;">${that.keyword}</span>`
							)
						}

					})

					var key = that.keyList.find(item => {
						return that.lang == 6 ? item.cn == that.keyword : item.en == that.keyword
					})
					var obj
					if (key.url) {
						obj = {
							title: this.lang == 6 ? key.cn : key.en,
							describe: this.lang == 6 ? key.cn : key.en,
							url: key.url
						}
						return this.list = [obj, ...res.list]
					}
					this.list = res.list
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.page {
		padding-top: 151px;
		position: relative;
		font-size: 18px;
		padding-bottom: 90px;
	}

	.content {
		margin: 0 60px;
		background-color: #EEEEEE;
		border-radius: 16px;
		padding: 56px 51px;
	}

	.b {
		font-weight: bold;
	}

	.text {
		font-family: SourceHanSansCN-Normal, sans-serif;
		font-size: 18px;
		font-weight: 350;
		line-height: 36px;
		color: #333333;
	}

	.titleBox {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin: 50px 111px;
		// padding: 0 111px;

		.title {
			font-family: SourceHanSansCN-Bold, sans-serif;
			font-size: 32px;
			font-weight: bold;
			color: #97233F;
			text-transform: uppercase;
		}

		.inputBox {
			display: flex;
			width: 469px;
			height: 51px;
			align-items: center;
			padding: 0 0px;
			justify-content: space-between;
			background-color: #FFF;
			border-radius: 5px;

			input {
				border: none;
				outline: none;
				padding: 0 20px;
			}

			/deep/.el-input__inner {
				height: 51px !important;
				font-size: 20px;
				border: none;
				outline: none;
			}

			/deep/.el-select {
				border: none;
				outline: none;
				height: 51px;
				width: 100%;
				font-family: SourceHanSansCN-Bold, sans-serif;
			}

			/deep/.el-select .el-input.is-focus .el-input__inner,
			/deep/ .el-select .el-input__inner:focus {
				border: none;
			}

			/deep/ .el-input__suffix-inner {
				display: none;
			}

			.btn {
				cursor: pointer;
				color: #FFF;
				width: 116px;
				height: 51px;
				border-radius: 4px;
				background: #97233F;
				box-shadow: 0px 12px 20px -5px rgba(23, 23, 126, 0.1);
				text-align: center;
				line-height: 51px;
				// font-family: SourceHanSansCN-Bold , sans-serif;
				font-size: 26px;
				// font-weight: bold;
				text-transform: uppercase;

			}
		}
	}

	.list {
		padding-bottom: 50px;

		.item {
			font-size: 18px;
			font-weight: 350;
			padding: 30px 0px;
			cursor: pointer;

			p {
				font-family: SourceHanSansCN-Medium, sans-serif;
				font-size: 26px;
				font-weight: 500;
				color: #97233F;
				margin-bottom: 20px;
			}

			div {
				display: -webkit-box;
				-webkit-box-orient: vertical;
				-webkit-line-clamp: 3;
				overflow: hidden;
				font-size: 20px;
				line-height: 46px;
			}
		}
	}
</style>